import React, { useState } from 'react';
import { useGenerateSoleFromObjectsMutation, useSoleMutation, usePipelineMutation } from 'app/apis';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import {
  selectSelectedDatabases,
  selectSelectedSchemas,
  selectSelectedTables,
  selectFlow,
  selectDataProductName,
  selectEnvironmentManagement,
  setGenerateSoleFromObjectsOutput,
  setSoleOutput,
} from '../reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import SourceSelector from '../components/SourceSelector';
import { sendMetrics, MetricType, ExitTo } from 'utils/metrics';
import { selectUser } from 'features/authentication/reducers/userSlice';

export interface DatabaseTablesStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function DatabaseTablesStep(props: DatabaseTablesStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [generateSoleFromObjects] = useGenerateSoleFromObjectsMutation();
  const [sole] = useSoleMutation();
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);
  const flow = useAppSelector(selectFlow);
  const dataProductName = useAppSelector(selectDataProductName);
  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const environmentManagement = useAppSelector(selectEnvironmentManagement);
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('Failed to generate config');
  const user = useAppSelector(selectUser);

  const [pipeline] = usePipelineMutation();

  const pathForLink = projectPath.toLowerCase().split(' ').join('-');
  const devReadyLink = `https://develop.dataops.live/quickstart?autostart=true#LAUNCH_MODE=DATA_PRODUCT_CREATOR/https://app.dataops.live/${pathForLink}/-/tree/${branch}/`;

  const onContinue = (finishEarly: boolean): void => {
    setLoading(true);
    setFailed(false);
    const databaseToSend = selectedDatabases.map((database) => database.database);
    const schemasToSend = selectedSchemas.map((schema) => {
      return { database: schema.database, object_id: schema.schema };
    });
    const tablesToSend = selectedTables.map((obj) => {
      return { database: obj.database, schema: obj.schema, object_id: obj.table };
    });
    generateSoleFromObjects({
      account: snowflakeAccount,
      databases: databaseToSend,
      schemas: schemasToSend,
      tables: tablesToSend,
    })
      .unwrap()
      .then((res: any) => {
        const dbtSole = res;
        dispatch(setGenerateSoleFromObjectsOutput(dbtSole));
        sole({
          dbtSole,
          manageSole: environmentManagement === 'managed' ? dbtSole : null,
          toIncludeModels: true,
          toWriteConfigs: true,
          toWriteJobs: true,
          dataProductName,
          projectName: projectPath,
          account: snowflakeAccount,
          branch,
        })
          .unwrap()
          .then((soleRes: any) => {
            dispatch(setSoleOutput(soleRes));
            if (finishEarly) {
              pipeline({ dataProductName, projectName: projectPath, branch, mergeMain: flow === 'ddl' })
                .unwrap()
                .then((res: any) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                  setErrorMessage(err.data);
                });
              sendMetrics(MetricType.create_analytics_end, user, null, ExitTo.developer);
              window.location.href = devReadyLink;
            } else {
              props.onContinue();
            }
          })
          .catch((soleErr) => {
            console.log(soleErr);
            setFailed(true);
            setErrorMessage(soleErr.data);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        setLoading(false);
      });
  };

  return (
    <StepWrapper
      title="Which tables would you like to use in your dataset?"
      subtitle="Select the tables to include in your dataset"
      onBack={() => props.onBack()}
      onContinue={() => onContinue(false)}
      continueDisabled={
        (selectedDatabases.length === 0 && selectedSchemas.length === 0 && selectedTables.length === 0) || loading
      }
      finishEarlyAvailable={true}
      finishEarlyContinueButtonText="Review tests"
      onFinishEarly={() => onContinue(true)}
    >
      <div className="w-full h-full flex flex-col items-center">
        <div className="flex w-full">
          <div className="w-1/2 h-full flex flex-col items-center">
            <div className="relative w-3/4 mb-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">{snowflakeAccount}</span>
              </div>
            </div>
            <SourceSelector mode="selection" />
          </div>
          <div className="w-1/2 h-full flex flex-col items-center">
            <div className="relative w-3/4 mb-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">{dataProductName}</span>
              </div>
            </div>
            <SourceSelector mode="preview" />
          </div>
        </div>
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />
      </div>
    </StepWrapper>
  );
}
