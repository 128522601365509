import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectCurrentStep, setCurrentStep } from 'app/sharedSlice';
import { selectFlow } from 'features/data-product-builder/reducers/builderSlice';
import DataProductDefinitionStep from 'features/data-product-builder/steps/DataProductDefinitionStep';
import MetadataImportStep from 'features/data-product-builder/steps/MetadataImportStep';
import DatabaseManagementStep from 'features/data-product-builder/steps/DatabaseManagementStep';
import ProductionDatabaseStep from 'features/data-product-builder/steps/ProductionDatabaseStep';
import DatabaseTablesStep from 'features/data-product-builder/steps/DatabaseTablesStep';
import GeneratedTestsStep from 'features/data-product-builder/steps/GeneratedTestsStep';
import DataProductSummaryStep from 'features/data-product-builder/steps/DataProductSummaryStep';
import { sendMetrics, MetricType } from 'utils/metrics';
import { selectUser } from 'features/authentication/reducers/userSlice';

export default function DataProductBuilderView(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectFlow);
  const currentStep = useAppSelector(selectCurrentStep);
  const user = useAppSelector(selectUser);

  const setStepAndSendMetric = (step: number): void => {
    sendMetrics(MetricType.create_analytics, user, step.toString(), null);
    dispatch(setCurrentStep(step));
  };
  return (
    <>
      {currentStep === 0 && (
        <DataProductDefinitionStep onBack={() => navigate('/')} onContinue={() => setStepAndSendMetric(1)} />
      )}
      {currentStep === 1 && (
        <MetadataImportStep
          onBack={() => setStepAndSendMetric(0)}
          onContinue={() => setStepAndSendMetric(flow === 'snowflake' ? 2 : 5)}
        />
      )}
      {currentStep === 2 && (
        <DatabaseManagementStep onBack={() => setStepAndSendMetric(1)} onContinue={() => setStepAndSendMetric(3)} />
      )}
      {currentStep === 3 && (
        <ProductionDatabaseStep onBack={() => setStepAndSendMetric(2)} onContinue={() => setStepAndSendMetric(4)} />
      )}
      {currentStep === 4 && (
        <DatabaseTablesStep onBack={() => setStepAndSendMetric(3)} onContinue={() => setStepAndSendMetric(5)} />
      )}
      {currentStep === 5 && (
        <GeneratedTestsStep
          onBack={() => setStepAndSendMetric(flow === 'snowflake' ? 4 : 1)}
          onContinue={() => setStepAndSendMetric(6)}
        />
      )}
      {currentStep === 6 && (
        <DataProductSummaryStep onBack={() => setStepAndSendMetric(5)} onContinue={() => setStepAndSendMetric(7)} />
      )}
    </>
  );
}
