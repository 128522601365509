import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface StepWrapperProps {
  title: string;
  subtitle: string;
  onBack: () => void;
  onContinue: () => void;
  continueDisabled?: boolean;
  children: React.ReactNode;
  isLastStep?: boolean;
  finishEarlyAvailable?: boolean;
  finishEarlyContinueButtonText?: string;
  onFinishEarly?: () => void;
}

export default function StepWrapper(props: StepWrapperProps): JSX.Element {
  const [historyIndex, setHistoryIndex] = useState(0);

  useEffect(() => {
    setHistoryIndex(window.history.state.idx);
  }, []);

  window.onpopstate = () => {
    if (window.history.state === null) {
      return;
    }

    if (window.history.state.idx < historyIndex) {
      props.onBack();
    } else {
      props.onContinue();
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="h-full flex flex-col">
      <div className="w-3/4 mx-[auto] text-center pb-6 flex flex-col items-center">
        <h2 className="text-base font-semibold text-gray-900">{props.title}</h2>
        <p className="mt-1 text-sm text-gray-600">{props.subtitle}</p>
      </div>

      <div className="grow overflow-y-scroll pb-6">{props.children}</div>

      <div className="pt-4 flex items-center justify-center gap-x-6 border-t border-gray-300">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900 hover:underline"
          onClick={() => props.onBack()}
        >
          Back
        </button>
        {props.finishEarlyAvailable !== true && (
          <button
            className="min-w-[7rem] rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400"
            onClick={() => {
              navigate(location.pathname);
              props.onContinue();
            }}
            disabled={props.continueDisabled}
          >
            {props.isLastStep === true ? 'Finish' : 'Continue'}
          </button>
        )}
        {props.finishEarlyAvailable === true && props.onFinishEarly !== undefined && (
          <>
            <button
              className="min-w-[7rem] rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400"
              onClick={() => {
                navigate(location.pathname);
                props.onContinue();
              }}
              disabled={props.continueDisabled}
            >
              {props.finishEarlyContinueButtonText}
            </button>
            <button
              className="min-w-[7rem] rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400"
              onClick={() => props.onFinishEarly?.()}
              disabled={props.continueDisabled}
            >
              Finish
            </button>
          </>
        )}
      </div>
    </div>
  );
}
