import React, { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utils/styleUtils';

interface DbtSelectorsProps {
  label: string;
  tags: string[];
  files: string[];
  selected: string | undefined;
  setSelected: (value: string) => void;
}

export default function DbtSelectors(props: DbtSelectorsProps): JSX.Element {
  const [query, setQuery] = useState('');
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<string[]>([]);

  useEffect(() => {
    setFilteredTags(query === '' ? props.tags : props.tags.filter((option) => option.includes(query)));
  }, [query, props.tags]);

  useEffect(() => {
    setFilteredFiles(query === '' ? props.files : props.files.filter((option) => option.includes(query)));
  }, [query, props.files]);

  return (
    <div className="w-full">
      <Combobox value={props.selected} onChange={props.setSelected}>
        {({ open }) => (
          <>
            <div className="w-full relative mt-1">
              <Combobox.Label className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                {props.label}
              </Combobox.Label>
              <div className="mt-1 relative w-full cursor-default rounded-md bg-white text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 sm:text-sm sm:leading-6 flex items-center">
                <Combobox.Button as="div" className="flex w-full">
                  <Combobox.Input
                    key={open ? 'open' : 'closed'}
                    className="w-full py-1.5 pl-1.5 pr-8 overflow-hidden text-black bg-transparent border-none text-sm focus:outline-none"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={() => {
                      if (open) {
                        return query;
                      }
                      return props.selected?.split('/').pop() ?? 'Selector';
                    }}
                  />
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Combobox.Options className="absolute z-50 mt-1 max-h-60 min-w-full max-w-full overflow-auto rounded-md bg-white pt-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {filteredTags.length === 0 && filteredFiles.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
                  ) : (
                    <>
                      <Combobox.Option
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-gray-100' : '',
                            'relative cursor-default select-none py-1 pl-1 pr-9 text-gray-900 font-semibold',
                          )
                        }
                        value={'*'}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className="truncate">Select everything</span>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-gray-600 bg-gray-100' : 'text-gray-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-2',
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                      {filteredTags.length !== 0 && <span className="pl-1 font-semibold text-gray-900">Tags</span>}
                      {filteredTags.map((tag) => (
                        <Combobox.Option
                          key={tag}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-gray-900 bg-gray-100' : 'text-gray-900',
                              'relative cursor-default select-none py-1 pl-3 pr-9',
                            )
                          }
                          value={tag}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate')}>
                                {tag}
                              </span>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-gray-600 bg-gray-100' : 'text-gray-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-2',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                      {filteredFiles.length !== 0 && <span className="pl-1 font-semibold text-gray-900">Files</span>}
                      {filteredFiles.map((file) => (
                        <Combobox.Option
                          key={file}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-gray-900 bg-gray-100' : 'text-gray-900',
                              'relative cursor-default select-none py-1 pl-3 pr-9',
                            )
                          }
                          value={file}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate')}>
                                {file}
                              </span>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-gray-600 bg-gray-100' : 'text-gray-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-2',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </>
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </>
        )}
      </Combobox>
    </div>
  );
}
