import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectCreateNewProject,
  selectSelectedGroup,
  selectSelectedProject,
  selectProjectsInGroup,
  selectNewProjectName,
  setCreateNewProject,
  setSelectedGroup,
  setSelectedProject,
  setProjectsInGroup,
  setNewProjectName,
} from 'app/sharedSlice';
import { Switch } from '@headlessui/react';
import ComboboxAutocomplete from './ComboboxAutocomplete';
import InformationTooltip from 'components/InformationTooltip';
import type { IGroup, IProject } from 'utils/types';
import { getConfig } from 'config/config-helper';
import { classNames } from 'utils/styleUtils';

const { dataopsliveBaseUrl } = getConfig();

export const sanitizeProjectName = (name: string): string => {
  const sanitizedProjectName = name.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9_\-.+\s]/g, '');
  return sanitizedProjectName;
};

const GroupAndProjectSelector = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const createNewProject = useAppSelector(selectCreateNewProject);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const selectedProject = useAppSelector(selectSelectedProject);
  const projectsInGroup = useAppSelector(selectProjectsInGroup);
  const newProjectName = useAppSelector(selectNewProjectName);

  const [groups, setGroups] = useState<IGroup[]>([]);
  const [groupSearchTerm, setGroupSearchTerm] = useState('');

  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  useEffect(() => {
    if (selectedGroup.name === '') return;

    axios
      .get(`${dataopsliveBaseUrl}/api/v4/groups/${selectedGroup.id}/projects?min_access_level=30`, {
        withCredentials: false,
      })
      .then((response) => {
        console.log(response.data);
        dispatch(
          setProjectsInGroup(
            response.data.map((project: any) => ({
              name: project.name,
              id: project.id,
              path: project.path_with_namespace,
            })),
          ),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedGroup]);

  useEffect(() => {
    axios
      .get(`${dataopsliveBaseUrl}/api/v4/groups?min_access_level=30&search=${groupSearchTerm}`, {
        withCredentials: false,
      })
      .then((response) => {
        console.log(response.data);
        setGroups(
          response.data.map((group: any) => ({
            name: group.name,
            id: group.id,
            path: group.full_path,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [groupSearchTerm]);

  return (
    <>
      <Switch.Group as="div" className="col-span-2 flex items-center justify-center">
        <Switch
          checked={createNewProject}
          onChange={() => dispatch(setCreateNewProject(!createNewProject))}
          className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
        >
          <span className="sr-only">Use setting</span>
          <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
          <span
            aria-hidden="true"
            className={classNames(
              createNewProject ? 'bg-dataops-primary-blue' : 'bg-gray-200',
              'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
            )}
          />
          <span
            aria-hidden="true"
            className={classNames(
              createNewProject ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-1 cursor-default">
          <span className="text-sm font-medium text-gray-700">Create new project</span>
        </Switch.Label>
      </Switch.Group>

      <div className="col-span-2 xl:col-span-1">
        <ComboboxAutocomplete
          label="DataOps group"
          options={groups}
          selected={selectedGroup}
          setSelected={(group: IGroup) => dispatch(setSelectedGroup(group))}
          query={groupSearchTerm}
          setQuery={setGroupSearchTerm}
          tooltip="Select the DataOps group to create the data product within"
        />
      </div>

      {createNewProject && (
        <div className="col-span-2 xl:col-span-1">
          <label htmlFor="new-project-name" className="flex items-center text-sm font-medium leading-6 text-gray-900">
            <div>New project name</div>
            <InformationTooltip tooltip="Enter a name for the DataOps project to create the data product within" />
          </label>
          <div className="mt-2">
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
              <input
                type="text"
                value={newProjectName}
                onChange={(e) => dispatch(setNewProjectName(sanitizeProjectName(e.target.value)))}
                name="new-project-name"
                id="new-project-name"
                autoComplete="new-project-name"
                className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                required
              />
            </div>
          </div>
        </div>
      )}

      {!createNewProject && (
        <div className="col-span-2 xl:col-span-1">
          <ComboboxAutocomplete
            label="DataOps project"
            options={projectsInGroup}
            selected={selectedProject}
            setSelected={(project: IProject) => dispatch(setSelectedProject(project))}
            query=""
            setQuery={() => {}}
            tooltip="Select the DataOps project to create the data product within"
          />
        </div>
      )}
    </>
  );
};

export default GroupAndProjectSelector;
