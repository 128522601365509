import React, { useEffect, useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import { parse } from 'yaml';
import { useSoleMutation, usePipelineMutation } from 'app/apis';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import {
  selectFlow,
  selectDataProductName,
  selectSoleOutput,
  selectDataQualityTests,
  selectNumberOfSelectedSourceTables,
  selectNumberOfGeneratedTests,
  selectGenerateAutomatedTests,
  selectGenerateSoleFromObjectsOutput,
  selectEnvironmentManagement,
  setNumberOfSelectedSourceTables,
  setNumberOfGeneratedTests,
  setGenerateAutomatedTests,
  setSoleOutput,
} from '../reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';
import InfoSection from 'components/InfoSection';
import type { ISoleOutput, ISoleColumn } from 'utils/types';
import { classNames } from 'utils/styleUtils';
import { sendMetrics, MetricType, ExitTo } from 'utils/metrics';
import { selectUser } from 'features/authentication/reducers/userSlice';

export interface GeneratedTestsStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function GeneratedTestsStep(props: GeneratedTestsStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectFlow);
  const dataProductName = useAppSelector(selectDataProductName);
  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const generateSoleFromObjectsOutput = useAppSelector(selectGenerateSoleFromObjectsOutput);
  const soleOutput = useAppSelector(selectSoleOutput);
  const dataQualityTests = useAppSelector(selectDataQualityTests);
  const numberOfSelectedSourceTables = useAppSelector(selectNumberOfSelectedSourceTables);
  const numberOfGeneratedTests = useAppSelector(selectNumberOfGeneratedTests);
  const generateAutomatedTests = useAppSelector(selectGenerateAutomatedTests);
  const environmentManagement = useAppSelector(selectEnvironmentManagement);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const user = useAppSelector(selectUser);

  const [loading, setLoading] = useState<boolean>(false);

  const parsedSoleOutput: ISoleOutput = parse(soleOutput);
  const tables = parsedSoleOutput !== null ? parsedSoleOutput.sources.map((source: any) => source.tables).flat() : [];

  const [sole] = useSoleMutation();
  const [pipeline] = usePipelineMutation();

  const pathForLink = projectPath.toLowerCase().split(' ').join('-');
  const devReadyLink = `https://develop.dataops.live/quickstart?autostart=true#LAUNCH_MODE=DATA_PRODUCT_CREATOR/https://app.dataops.live/${pathForLink}/-/tree/${branch}/`;

  const stats = [
    { name: 'Source tables', value: numberOfSelectedSourceTables },
    { name: 'Tests generated', value: numberOfGeneratedTests },
  ];

  useEffect(() => {
    dispatch(setNumberOfSelectedSourceTables(tables.length));
    const columnsWithTests = tables.map((table) => table.columns.filter((column: any) => column.tests.length > 0));
    const numberOfGeneratedTests = columnsWithTests.flat().length;
    dispatch(setNumberOfGeneratedTests(numberOfGeneratedTests));
  }, [tables]);

  const updateTests = (automatedTests: boolean): void => {
    setLoading(true);
    sole({
      dbtSole: generateSoleFromObjectsOutput,
      manageSole: environmentManagement === 'managed' ? generateSoleFromObjectsOutput : null,
      toIncludeModels: true,
      toWriteConfigs: true,
      toWriteJobs: true,
      dataProductName,
      projectName: projectPath,
      account: snowflakeAccount,
      branch,
      augment: automatedTests,
    })
      .unwrap()
      .then((soleRes: any) => {
        console.log(soleRes);
        dispatch(setSoleOutput(soleRes));
      })
      .catch((soleErr) => {
        console.log(soleErr);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishEarly = (): void => {
    pipeline({ dataProductName, projectName: projectPath, branch, mergeMain: flow === 'ddl' })
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    sendMetrics(MetricType.create_analytics_end, user, null, ExitTo.developer);
    window.location.href = devReadyLink;
  };

  return (
    <StepWrapper
      title="Review the data quality tests"
      subtitle="Summary of the data quality tests generated automatically and passed for the selected tables"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
      continueDisabled={false}
      finishEarlyAvailable={true}
      finishEarlyContinueButtonText="Review summary"
      onFinishEarly={() => onFinishEarly()}
    >
      <div className="w-full wide:w-3/4 min-h-full mx-[auto] flex flex-col justify-end px-4 xl:px-8">
        <Switch.Group as="div" className="relative w-fit mx-[auto] flex flex-col items-center justify-center mb-1">
          <Switch
            checked={generateAutomatedTests}
            onChange={() => {
              updateTests(!generateAutomatedTests);
              dispatch(setGenerateAutomatedTests(!generateAutomatedTests));
            }}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
          >
            <span className="sr-only">Use setting</span>
            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
            <span
              aria-hidden="true"
              className={classNames(
                generateAutomatedTests ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
              )}
            />
            <span
              aria-hidden="true"
              className={classNames(
                generateAutomatedTests ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-1 cursor-default">
            <span className="text-sm font-medium text-gray-700">Generate automatic tests based on SLO</span>
          </Switch.Label>
          {loading && (
            <div className="absolute bottom-6 right-0 min-h-6 flex items-center justify-center">
              <div
                className="animate-spin inline-block w-5 h-5 border-[1px] border-current border-t-transparent text-cyan-800 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </Switch.Group>
        <div className="flex items-end mb-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium leading-6 text-gray-900">Service Level Objectives</label>
            <div className="mt-2">
              <textarea
                rows={3}
                value={dataQualityTests}
                disabled
                className="block w-full rounded-md border-0 p-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400"
              />
            </div>
          </div>
          <div className="text-sm text-gray-600 px-4">
            <dl className="border border-gray-300 rounded-md overflow-hidden mx-auto grid grid-cols-2 gap-px bg-gray-900/5">
              {stats.map((stat) => (
                <div
                  key={stat.name}
                  className="flex flex-wrap items-baseline justify-between gap-x-2 gap-y-1 bg-white px-6 py-2"
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                  <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="grid grid-cols-3 wide:grid-cols-4 gap-4 mb-4">
          {tables.slice(0, 30).map((table) => (
            <div
              key={table.name}
              className="max-h-[20rem] relative overflow-scroll text-sm p-4 pt-0 text-gray-700 rounded-md bg-gray-50 border border-gray-200"
            >
              <div className="flex mb-1 sticky top-0 bg-gray-50 pt-4 pb-2">
                <div className="min-w-[20px] w-5 mr-1">
                  <img className="w-5" src="/static/table-icon.svg" alt="Table" />
                </div>
                <span>{table.name}</span>
              </div>
              <div className="pl-8 space-y-1 overflow-hidden">
                {table.columns.map((column: ISoleColumn) => (
                  <div key={column.name} className="flex space-x-2">
                    <span>{column.name}</span>
                    {column.tests.map((test) => (
                      <span
                        key={test}
                        className="inline-flex items-center rounded-md bg-indigo-50 px-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
                      >
                        {test}
                      </span>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {tables.length === 0 && <div className="text-gray-700">No tests generated</div>}
        <div className="w-3/4 mt-[auto] self-center">
          {numberOfGeneratedTests === 0 && (
            <div className="rounded-md bg-yellow-50 p-4 mb-2">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-yellow-700">
                    No tests found. Make sure to add tests in the next steps during designing your dataset.
                  </div>
                </div>
              </div>
            </div>
          )}
          <InfoSection text="This page shows a summary of the default data quality rules (tests applied on your MATE models) that passed for the tables you picked earlier. If you want to make changes, you can customize these rules during the design phase of the data product, after you define all its components." />
        </div>
      </div>
    </StepWrapper>
  );
}
