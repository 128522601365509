import React, { Fragment, useEffect } from 'react';
import InformationTooltip from 'components/InformationTooltip';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utils/styleUtils';

interface DropdownSingleselectProps {
  label: string;
  options: string[];
  selected: string;
  setSelected: (value: string) => void;
  tooltip?: string;
}

export default function DropdownSingleselect(props: DropdownSingleselectProps): JSX.Element {
  useEffect(() => {
    const selectedPresent = props.options.some((option) => option === props.selected);
    if (!selectedPresent) props.setSelected('');
  }, [props.options]);

  return (
    <div className="w-full">
      <Listbox value={props.selected} onChange={props.setSelected}>
        {({ open }) => (
          <>
            <Listbox.Label className="text-sm font-medium leading-6 text-gray-900 flex items-center">
              <div>{props.label}</div>
              {props.tooltip !== undefined && <InformationTooltip tooltip={props.tooltip} />}
            </Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-1.5 pr-10 text-left text-black shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 sm:text-sm sm:leading-6">
                <span className="block truncate">{props.selected}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
                {props.options.length === 0 && (
                  <div className="text-gray-600">{`No ${props.label.toLowerCase()}s`}</div>
                )}
                {props.options.length !== 0 && props.selected === '' && (
                  <div className="text-gray-600">{`Select a ${props.label.toLowerCase()}`}</div>
                )}
              </Listbox.Button>

              <Transition
                show={open && props.options.length > 0}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {props.options.map((option) => (
                    <Listbox.Option
                      key={option}
                      className={({ active }) =>
                        classNames(active ? 'bg-gray-100' : '', 'relative cursor-default select-none py-2 pl-3 pr-9')
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {option}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-gray-900' : 'text-gray-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
