import React, { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Disclosure, Transition } from '@headlessui/react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import {
  selectSelectedDatabases,
  selectSelectedSchemas,
  selectSelectedTables,
  selectDatabase,
  unselectDatabase,
  addSchemas,
} from '../reducers/builderSlice';
import { useGetSchemasQuery } from 'app/apis';
import SchemaItem from './SchemaItem';
import { classNames } from 'utils/styleUtils';

interface DatabaseItemProps {
  databaseName: string;
  openByDefault?: boolean;
  mode: 'selection' | 'preview';
}

export default function DatabaseItem(props: DatabaseItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);
  const { data: schemas, isLoading } = useGetSchemasQuery({
    account: snowflakeAccount,
    database: props.databaseName,
  });
  const [filteredSchemas, setFilteredSchemas] = useState<string[]>([]);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (schemas !== undefined) {
      const schemaArray = schemas.map((schema) => {
        return { database: props.databaseName, schema };
      });
      dispatch(addSchemas({ databaseKey: props.databaseName, schemas: schemaArray }));
    }
  }, [schemas]);

  // In preview mode, only show the selected schemas
  useEffect(() => {
    if (schemas !== undefined) {
      if (props.mode === 'selection') {
        setFilteredSchemas(schemas);
      } else {
        setFilteredSchemas(
          schemas.filter(
            (schema) =>
              selectedTables.some(
                (selected) => selected.database === props.databaseName && selected.schema === schema,
              ) ||
              selectedSchemas.some((selected) => selected.schema === schema) ||
              selectedDatabases.some((selected) => selected.database === props.databaseName),
          ),
        );
      }
    }
  }, [schemas, selectedDatabases, selectedSchemas]);

  useEffect(() => {
    selectedDatabases.find((database) => database.database === props.databaseName) !== undefined
      ? setSelected(true)
      : setSelected(false);
  }, [selectedDatabases]);

  const onClick = (): void => {
    if (selected) {
      dispatch(unselectDatabase({ database: props.databaseName }));
    } else {
      dispatch(selectDatabase({ database: props.databaseName }));
    }
    setSelected(!selected);
  };

  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div
            className={classNames(
              'w-3/4 relative flex items-center justify-start py-1 border border-gray-300 rounded-md text-gray-700 bg-gray-50 cursor-default',
              props.mode === 'selection' ? 'px-4' : 'px-2',
            )}
          >
            {props.mode === 'selection' && (
              <div className="absolute left-2">
                <input
                  type="checkbox"
                  checked={selected}
                  onChange={() => onClick()}
                  className="rounded border-gray-300 cursor-pointer"
                />
              </div>
            )}
            <div className={classNames('min-w-[20px] w-5', props.mode === 'selection' ? 'ml-2' : '')}>
              <img className="w-5" src="/static/database-icon.svg" alt="Database" />
            </div>
            <div className="text-sm truncate mr-2">{props.databaseName}</div>
            <div className="absolute right-1 w-5 cursor-pointer">
              <ChevronDownIcon
                className={classNames(
                  open ? 'transform rotate-90' : '',
                  'w-5 h-5 ml-auto text-gray-700 transition duration-150 ease-in-out',
                )}
              />
            </div>
          </div>

          <Transition
            show={open || props.openByDefault === true}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className="w-3/4 px-4"
          >
            <Disclosure.Panel className="space-y-1">
              {filteredSchemas.map((schema) => (
                <SchemaItem key={schema} databaseName={props.databaseName} schemaName={schema} mode={props.mode} />
              ))}
              {isLoading && (
                <div className="mt-1 min-h-6 flex items-center justify-center">
                  <div
                    className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
