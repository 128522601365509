import React from 'react';
import { useGetDatabasesQuery } from 'app/apis';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectSnowflakeAccount, selectSelectedDatabase, setSelectedDatabase } from 'app/snowflakeSlice';
import LoadingAndErrorSection from './LoadingAndErrorSection';
import { classNames } from 'utils/styleUtils';

interface DatabaseSelectorProps {
  onSelect: (database: string) => void;
}

export default function DatabaseSelector(props: DatabaseSelectorProps): JSX.Element {
  const dispatch = useAppDispatch();
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabase = useAppSelector(selectSelectedDatabase);
  const { data: databases, isLoading, isError, error } = useGetDatabasesQuery({ account: snowflakeAccount });

  return (
    <>
      {!isError && (
        <div className="mt-8 mb-4 space-y-1 flex flex-col items-center">
          {databases?.map((database) => (
            <div
              key={database}
              className={classNames(
                database === selectedDatabase
                  ? 'text-gray-900 bg-gray-300 border-gray-500 hover:bg-gray-400'
                  : 'text-gray-700 border-gray-300 bg-gray-50 hover:bg-gray-100',
                'w-3/4 md:w-1/2 xl:w-1/3 flex py-1 px-4 rounded-md  cursor-pointer border ',
              )}
              onClick={() => {
                props.onSelect(database);
                dispatch(setSelectedDatabase(database));
              }}
            >
              <div className="min-w-[20px] w-5">
                <img className="w-5" src="/static/database-icon.svg" alt="Database" />
              </div>
              <div className="text-sm truncate">{database}</div>
            </div>
          ))}
          {isLoading && (
            <div className="min-h-8 flex items-center justify-center">
              <div
                className="animate-spin inline-block w-8 h-8 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!isLoading && databases?.length === 0 && <div className="text-sm text-gray-500">No databases found</div>}
        </div>
      )}
      <LoadingAndErrorSection
        isLoading={false}
        isFailed={isError}
        errorMessage={
          error !== undefined && 'data' in (error as any) ? (error as any).data : 'Failed to load databases'
        }
      />
    </>
  );
}
