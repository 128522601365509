import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectEnvironmentManagement, setEnvironmentManagement } from '../reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';

export interface DatabaseManagementStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function DatabaseManagementStep(props: DatabaseManagementStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const environmentManagement = useAppSelector(selectEnvironmentManagement);

  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  return (
    <StepWrapper
      title="How would you like to manage the database containing the dataset source?"
      subtitle="Manage the source database in the DataOps.live platform declaratively or keep the source database management external"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
      continueDisabled={environmentManagement === 'not-selected'}
    >
      <div className="h-full flex flex-col items-center">
        <fieldset className="w-1/2">
          <div className="divide-y divide-gray-200">
            <div className="relative flex items-start pb-4 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="snowflake-option" className="font-medium text-gray-900">
                  Manage the source database with DataOps.live (recommended)
                </label>
                <p className="text-gray-500">
                  Clone and manage the selected source database for the dataset within DataOps.live platform in a safe
                  environment. This creates a source of truth for your database as code and improves reliability and
                  management of your database.
                </p>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id="snowflake-option"
                  name="snowflake-option"
                  type="radio"
                  checked={environmentManagement === 'managed'}
                  onChange={() => {
                    dispatch(setEnvironmentManagement('managed'));
                  }}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
            <div className="relative flex items-start pb-4 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="ddl-option" className="font-medium text-gray-900">
                  Don&apos;t manage the source database with DataOps.live
                </label>
                <p className="text-gray-500">
                  Clone the source database for the dataset to a DataOps.live safe environment but keep the management
                  of the database schema outside the platform. If you wish to do changes to the source database, changes
                  will need to be done to the database directly which decreases productivity and increases risk.
                </p>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id="ddl-option"
                  name="ddl-option"
                  type="radio"
                  checked={environmentManagement === 'not-managed'}
                  onChange={() => {
                    dispatch(setEnvironmentManagement('not-managed'));
                  }}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </StepWrapper>
  );
}
