import React from 'react';
import StepWrapper from 'components/StepWrapper';
import DatabaseSelector from 'components/DatabaseSelector';

export interface TargetDatabaseStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function TargetDatabaseStep(props: TargetDatabaseStepProps): JSX.Element {
  return (
    <StepWrapper
      title="Target database"
      subtitle="Select the target database"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full">
        <DatabaseSelector onSelect={() => {}} />
      </div>
    </StepWrapper>
  );
}
