import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import type { RootState } from 'app/store';
import type { IGroup, IProject } from 'utils/types';

export interface ISharedState {
  currentStep: number;
  numberOfSteps: number;
  createNewProject: boolean;
  selectedGroup: IGroup;
  selectedProject: IProject;
  projectsInGroup: IProject[];
  newProjectName: string;
  projectPath: string;
  // If the user fills in the data product defintion form, selects 'Create new project' and clicks 'Continue',
  // then a new project will be created on the Platform. If the user decides to navigate back, make changes then we don't have to
  // create a new project again. The 'isNewProject' param set for the API call should be set to false.
  // This array is used to store the projects created in the current session.
  projectsCreatedInCurrentSession: string[];
  branch: string;
  pipelineId: number;
}

export const initialState: ISharedState = {
  currentStep: 0,
  numberOfSteps: 0,
  createNewProject: true,
  selectedGroup: { id: 0, name: '', path: '' },
  selectedProject: { id: 0, name: '', path: '' },
  projectsInGroup: [],
  newProjectName: '',
  projectPath: '',
  projectsCreatedInCurrentSession: [],
  branch: '',
  pipelineId: 0,
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setNumberOfSteps: (state, action: PayloadAction<number>) => {
      state.numberOfSteps = action.payload;
    },
    setCreateNewProject: (state, action: PayloadAction<boolean>) => {
      state.createNewProject = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<IGroup>) => {
      state.selectedGroup = action.payload;
    },
    setSelectedProject: (state, action: PayloadAction<IProject>) => {
      state.selectedProject = action.payload;
    },
    setProjectsInGroup: (state, action: PayloadAction<IProject[]>) => {
      state.projectsInGroup = action.payload;
    },
    setNewProjectName: (state, action: PayloadAction<string>) => {
      state.newProjectName = action.payload;
    },
    setProjectPath: (state, action: PayloadAction<string>) => {
      state.projectPath = action.payload;
    },
    setProjectsCreatedInCurrentSession: (state, action: PayloadAction<string[]>) => {
      state.projectsCreatedInCurrentSession = action.payload;
    },
    setBranch: (state, action: PayloadAction<string>) => {
      state.branch = action.payload;
    },
    setPipelineId: (state, action: PayloadAction<number>) => {
      state.pipelineId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setCurrentStep,
  setNumberOfSteps,
  setCreateNewProject,
  setSelectedGroup,
  setSelectedProject,
  setProjectsInGroup,
  setNewProjectName,
  setProjectPath,
  setProjectsCreatedInCurrentSession,
  setBranch,
  setPipelineId,
} = sharedSlice.actions;

export const selectCurrentStep = (state: RootState): number => state.shared.currentStep;
export const selectNumberOfSteps = (state: RootState): number => state.shared.numberOfSteps;
export const selectCreateNewProject = (state: RootState): boolean => state.shared.createNewProject;
export const selectSelectedGroup = (state: RootState): IGroup => state.shared.selectedGroup;
export const selectSelectedProject = (state: RootState): IProject => state.shared.selectedProject;
export const selectProjectsInGroup = (state: RootState): IProject[] => state.shared.projectsInGroup;
export const selectNewProjectName = (state: RootState): string => state.shared.newProjectName;
export const selectProjectPath = (state: RootState): string => state.shared.projectPath;
export const selectProjectsCreatedInCurrentSession = (state: RootState): string[] =>
  state.shared.projectsCreatedInCurrentSession;
export const selectBranch = (state: RootState): string => state.shared.branch;
export const selectPipelineId = (state: RootState): number => state.shared.pipelineId;

export default sharedSlice.reducer;
