import React from 'react';
import { Outlet } from 'react-router-dom';
import AppBar from 'components/AppBar';

export default function App(): JSX.Element {
  return (
    <div className="h-full">
      <div className="w-full fixed top-0 z-40">
        <AppBar />
      </div>

      <div className="flex h-full flex-col mt-[3.9rem]">
        <div className="flex h-full">
          {/* <Sidebar /> */}

          <main className="min-w-0 flex-1 border-t border-gray-200">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}
