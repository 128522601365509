import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import type { RootState } from 'app/store';

export interface IDbtMigrationState {
  dbtProjectFilename: string;
  seedJobSelected: boolean;
  testSourcesJobSelected: boolean;
  buildModelsJobSelected: boolean;
  testModelsJobSelected: boolean;
  documentationJobSelected: boolean;
  seedSelector: string;
  sourceSelector: string;
  modelSelector: string;
  dbtVersion: string;
  compileDbt: boolean;
  selectorTags: string[];
  selectorFiles: string[];
}

export const initialState: IDbtMigrationState = {
  dbtProjectFilename: '',
  seedJobSelected: true,
  testSourcesJobSelected: false,
  buildModelsJobSelected: true,
  testModelsJobSelected: true,
  documentationJobSelected: true,
  seedSelector: '*',
  sourceSelector: '*',
  modelSelector: '*',
  dbtVersion: '1.5',
  compileDbt: true,
  selectorTags: [],
  selectorFiles: [],
};

export const dbtMigrationSlice = createSlice({
  name: 'dbtMigration',
  initialState,
  reducers: {
    setDbtProjectFilename: (state, action: PayloadAction<string>) => {
      state.dbtProjectFilename = action.payload;
    },
    setSeedJobSelected: (state, action: PayloadAction<boolean>) => {
      state.seedJobSelected = action.payload;
    },
    setTestSourcesJobSelected: (state, action: PayloadAction<boolean>) => {
      state.testSourcesJobSelected = action.payload;
    },
    setBuildModelsJobSelected: (state, action: PayloadAction<boolean>) => {
      state.buildModelsJobSelected = action.payload;
    },
    setTestModelsJobSelected: (state, action: PayloadAction<boolean>) => {
      state.testModelsJobSelected = action.payload;
    },
    setDocumentationJobSelected: (state, action: PayloadAction<boolean>) => {
      state.documentationJobSelected = action.payload;
    },
    setSeedSelector: (state, action: PayloadAction<string>) => {
      state.seedSelector = action.payload;
    },
    setSourceSelector: (state, action: PayloadAction<string>) => {
      state.sourceSelector = action.payload;
    },
    setModelSelector: (state, action: PayloadAction<string>) => {
      state.modelSelector = action.payload;
    },
    setDbtVersion: (state, action: PayloadAction<string>) => {
      state.dbtVersion = action.payload;
    },
    setCompileDbt: (state, action: PayloadAction<boolean>) => {
      state.compileDbt = action.payload;
    },
    setSelectorTags: (state, action: PayloadAction<string[]>) => {
      state.selectorTags = action.payload;
    },
    setSelectorFiles: (state, action: PayloadAction<string[]>) => {
      state.selectorFiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setDbtProjectFilename,
  setSeedJobSelected,
  setTestSourcesJobSelected,
  setBuildModelsJobSelected,
  setTestModelsJobSelected,
  setDocumentationJobSelected,
  setSeedSelector,
  setSourceSelector,
  setModelSelector,
  setDbtVersion,
  setCompileDbt,
  setSelectorTags,
  setSelectorFiles,
} = dbtMigrationSlice.actions;

export const selectDbtProjectFilename = (state: RootState): string => state.dbtMigration.dbtProjectFilename;
export const selectSeedJobSelected = (state: RootState): boolean => state.dbtMigration.seedJobSelected;
export const selectTestSourcesJobSelected = (state: RootState): boolean => state.dbtMigration.testSourcesJobSelected;
export const selectBuildModelsJobSelected = (state: RootState): boolean => state.dbtMigration.buildModelsJobSelected;
export const selectTestModelsJobSelected = (state: RootState): boolean => state.dbtMigration.testModelsJobSelected;
export const selectDocumentationJobSelected = (state: RootState): boolean =>
  state.dbtMigration.documentationJobSelected;
export const selectSeedSelector = (state: RootState): string => state.dbtMigration.seedSelector;
export const selectSourceSelector = (state: RootState): string => state.dbtMigration.sourceSelector;
export const selectModelSelector = (state: RootState): string => state.dbtMigration.modelSelector;
export const selectDbtVersion = (state: RootState): string => state.dbtMigration.dbtVersion;
export const selectCompileDbt = (state: RootState): boolean => state.dbtMigration.compileDbt;
export const selectSelectorTags = (state: RootState): string[] => state.dbtMigration.selectorTags;
export const selectSelectorFiles = (state: RootState): string[] => state.dbtMigration.selectorFiles;

export default dbtMigrationSlice.reducer;
