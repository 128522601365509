import { useAppSelector } from 'app/hooks';
import { selectFlow } from 'features/data-product-builder/reducers/builderSlice';
import type { IStep } from 'utils/types';

export default function getDataProductCreatorSteps(): IStep[] {
  const flow = useAppSelector(selectFlow);

  return [
    {
      name: 'Data product definition ',
      description: "What's the definition of your data product?",
      disabled: false,
    },
    {
      name: 'Dataset source',
      description: 'Where does the dataset source metadata come from?',
      disabled: false,
    },
    {
      name: 'Dataset management',
      description: 'How would you like to manage the database containing the dataset source?',
      disabled: flow === 'ddl',
    },
    {
      name: 'Dataset schema',
      description: 'Which database would you like to select tables from to add to the dataset?',
      disabled: flow === 'ddl',
    },
    {
      name: 'Dataset schema',
      description: 'Which tables would you like to use in your dataset?',
      disabled: flow === 'ddl',
    },
    {
      name: 'Service Level Indicator (SLI)',
      description: 'Review the data quality tests ',
      disabled: false,
    },
    {
      name: 'Data product summary',
      description: 'Review your data product',
      disabled: false,
    },
  ];
}
