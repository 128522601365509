import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { gql } from '@apollo/client';
import client from 'apolloClient';

type IRequestStatus = 'idle' | 'loading' | 'failed';

export interface IUserState {
  username: string;
  userRequestStatus: IRequestStatus;
}

const initialState: IUserState = {
  username: '',
  userRequestStatus: 'idle',
};

export const fetchUser = createAsyncThunk('user', async (token: string) => {
  const GET_USERNAME = gql`
    query {
      currentUser {
        username
        avatarUrl
        groupMemberships {
          nodes {
            group {
              fullPath
            }
          }
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_USERNAME,
    context: { headers: { authorization: `Bearer ${token}` } },
  });
  return response.data;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.userRequestStatus = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.userRequestStatus = 'idle';
        state.username = action.payload.currentUser.username;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.userRequestStatus = 'failed';
      });
  },
});

export const selectUser = (state: RootState): string => state.user.username;

export default userSlice.reducer;
