import React, { useContext, useRef, useEffect } from 'react';
import { IAuthContext } from 'react-oauth2-code-pkce';
import { AuthContext } from 'react-oauth2-code-pkce/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function OauthCallback(): JSX.Element {
  const { token, login, loginInProgress } = useContext<IAuthContext>(AuthContext);
  const tokenRef = useRef(token);
  const navigate = useNavigate();

  useEffect(() => {
    if (token !== '') {
      navigate('/');
    }
  }, [token]);

  function loginWithRetry(retrigger: boolean): void {
    // Already logged in, nothing to do
    if (tokenRef.current !== '') {
      return;
    }

    if (!loginInProgress || retrigger) {
      login();
    }

    setTimeout(() => {
      loginWithRetry(true);
    }, 5000);
  }

  useEffect(() => {
    tokenRef.current = token;
    loginWithRetry(false);
  }, [token]);

  return <div>Authorizing</div>;
}
