import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectSelectedDatabases,
  selectSelectedSchemas,
  selectSelectedTables,
  selectTable,
  unselectTable,
} from '../reducers/builderSlice';
import { classNames } from 'utils/styleUtils';

interface TableItemProps {
  databaseName: string;
  schemaName: string;
  tableName: string;
  mode: 'selection' | 'preview';
}

export default function TableItem(props: TableItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const databaseSelected = selectedDatabases.find((database) => database.database === props.databaseName);
    const schemaSelected = selectedSchemas.find(
      (schema) => schema.schema === props.schemaName && schema.database === props.databaseName,
    );
    const tableSelected = selectedTables.find(
      (table) =>
        table.database === props.databaseName && table.schema === props.schemaName && table.table === props.tableName,
    );
    if (databaseSelected !== undefined || schemaSelected !== undefined || tableSelected !== undefined) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedDatabases, selectedSchemas, selectedTables]);

  const onClick = (): void => {
    if (selected) {
      dispatch(unselectTable({ table: props.tableName, schema: props.schemaName, database: props.databaseName }));
    } else {
      dispatch(selectTable({ table: props.tableName, schema: props.schemaName, database: props.databaseName }));
    }
    setSelected(!selected);
  };

  return (
    <div
      className={classNames(
        'w-full relative items-center justify-start flex py-1 rounded-md border text-gray-700 bg-gray-50 cursor-default',
        props.mode === 'selection' ? 'px-4' : 'px-2',
      )}
    >
      {props.mode === 'selection' && (
        <div className="absolute left-2">
          <input
            type="checkbox"
            checked={selected}
            onChange={() => onClick()}
            className="rounded border-gray-300 cursor-pointer"
          />
        </div>
      )}
      <div className={classNames('min-w-[20px] w-5 mr-1', props.mode === 'selection' ? 'ml-2' : '')}>
        <img className="w-5" src="/static/table-icon.svg" alt="Table" />
      </div>
      <div className="text-sm truncate">{props.tableName}</div>
    </div>
  );
}
