export enum MetricType {
  create_analytics = 'dataops-create-analytics-step',
  create_analytics_end = 'dataops-create-analytics-end',
  create_dbt = 'dataops-create-dbt-step',
  create_data_science = 'dataops-create-dataScience-step',
  create_streamlit = 'dataops-create-streamlit-step',
  create_native_app = 'dataops-create-nativeApp-step',
}

export enum ExitTo {
  developer = 'develop',
  pipeline = 'pipeline',
  snowsight = 'snowsight',
}

export function sendMetrics(
  metric: MetricType,
  userId: string,
  flowStep?: string | null,
  exitTo?: ExitTo | null,
): void {
  const body = {
    event_name: metric,
    event_version: 1,
    event_source: 'create.dataops.live',
    properties: {
      user_id: userId,
      flow_step: flowStep,
      flow_exit: exitTo,
    },
  };
  console.log(body);
  fetch('https://metrics-ingest.dataops.live/metric', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
}
