import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import sharedReducer from './sharedSlice';
import snowflakeReducer from './snowflakeSlice';
import userReducer from '../features/authentication/reducers/userSlice';
import builderReducer from '../features/data-product-builder/reducers/builderSlice';
import dbtMigrationReducer from 'features/dbt-migration/reducers/dbtMigrationSlice';
import { builderApi } from './apis';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

const persistSharedConfig = {
  key: 'shared',
  storage,
  throttle: 0,
};

const persistSnowflakeConfig = {
  key: 'snowflake',
  storage,
  throttle: 0,
};

const persistBuilderConfig = {
  key: 'builder',
  storage,
  throttle: 0,
};

const persistDbtMigrationConfig = {
  key: 'dbtMigration',
  storage,
  throttle: 0,
};

const persistedSharedReducer = persistReducer(persistSharedConfig, sharedReducer);
const persistedSnowflakeReducer = persistReducer(persistSnowflakeConfig, snowflakeReducer);
const persistedBuilderReducer = persistReducer(persistBuilderConfig, builderReducer);
const persistedDbtMigrationReducer = persistReducer(persistDbtMigrationConfig, dbtMigrationReducer);

export const store = configureStore({
  reducer: {
    shared: persistedSharedReducer,
    snowflake: persistedSnowflakeReducer,
    user: userReducer,
    builder: persistedBuilderReducer,
    dbtMigration: persistedDbtMigrationReducer,
    [builderApi.reducerPath]: builderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(builderApi.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
