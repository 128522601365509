import React, { useState, useEffect } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Disclosure, Transition } from '@headlessui/react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import {
  selectSelectedDatabases,
  selectSelectedSchemas,
  selectSelectedTables,
  selectSchema,
  unselectSchema,
  addTables,
} from '../reducers/builderSlice';
import { useGetSchemaLevelObjectsQuery } from 'app/apis';
import TableItem from './TableItem';
import { classNames } from 'utils/styleUtils';

interface SchemaItemProps {
  databaseName: string;
  schemaName: string;
  mode: 'selection' | 'preview';
}

export default function SchemaItem(props: SchemaItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);
  const { data: schemaLevelObjects, isLoading } = useGetSchemaLevelObjectsQuery({
    account: snowflakeAccount,
    database: props.databaseName,
    schema: props.schemaName,
    object_type: 'table',
  });
  const [filteredTables, setFilteredTables] = useState<string[]>([]);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (schemaLevelObjects !== undefined) {
      const tableArray = schemaLevelObjects.map((table) => {
        return { database: props.databaseName, schema: props.schemaName, table };
      });
      dispatch(addTables({ schemaKey: `${props.databaseName}.${props.schemaName}`, tables: tableArray }));
    }
  }, [schemaLevelObjects]);

  // In preview mode, only show the selected tables
  useEffect(() => {
    if (schemaLevelObjects !== undefined) {
      if (props.mode === 'selection') {
        setFilteredTables(schemaLevelObjects);
      } else {
        setFilteredTables(
          schemaLevelObjects.filter(
            (table) =>
              selectedTables.some(
                (selected) =>
                  selected.database === props.databaseName &&
                  selected.schema === props.schemaName &&
                  selected.table === table,
              ) ||
              selectedSchemas.some(
                (selected) => selected.database === props.databaseName && selected.schema === props.schemaName,
              ) ||
              selectedDatabases.some((selected) => selected.database === props.databaseName),
          ),
        );
      }
    }
  }, [schemaLevelObjects, selectedDatabases, selectedSchemas, selectedTables]);

  useEffect(() => {
    const databaseSelected = selectedDatabases.find((database) => database.database === props.databaseName);
    const schemaSelected = selectedSchemas.find(
      (schema) => schema.schema === props.schemaName && schema.database === props.databaseName,
    );
    if (databaseSelected !== undefined || schemaSelected !== undefined) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedDatabases, selectedSchemas]);

  const onClick = (): void => {
    if (selected) {
      dispatch(unselectSchema({ schema: props.schemaName, database: props.databaseName }));
    } else {
      dispatch(selectSchema({ schema: props.schemaName, database: props.databaseName }));
    }
    setSelected(!selected);
  };

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div
            className={classNames(
              'w-full relative flex py-1 border border-gray-300 rounded-md text-gray-700 bg-gray-50 cursor-default',
              props.mode === 'selection' ? 'px-4' : 'px-2',
            )}
          >
            {props.mode === 'selection' && (
              <div className="absolute left-2">
                <input
                  type="checkbox"
                  checked={selected}
                  onChange={() => onClick()}
                  className="rounded border-gray-300 cursor-pointer"
                />
              </div>
            )}
            <div className={classNames('min-w-[20px] w-5 mr-1', props.mode === 'selection' ? 'ml-2' : '')}>
              <img className="w-5" src="/static/schema-icon.svg" alt="Schema" />
            </div>
            <div className="text-sm truncate mr-2">{props.schemaName}</div>
            <Disclosure.Button className="absolute right-1 w-5 cursor-pointer">
              <ChevronRightIcon
                className={classNames(
                  open ? 'transform rotate-90' : '',
                  'w-5 h-5 ml-auto text-gray-700 transition duration-150 ease-in-out',
                )}
              />
            </Disclosure.Button>
          </div>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacitsy-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className="px-4"
          >
            <Disclosure.Panel className="space-y-1">
              {!isLoading &&
                filteredTables.map((table) => (
                  <TableItem
                    key={table}
                    databaseName={props.databaseName}
                    schemaName={props.schemaName}
                    tableName={table}
                    mode={props.mode}
                  />
                ))}
              {isLoading && (
                <div className="my-1 min-h-6 flex items-center justify-center">
                  <div
                    className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
                    role="status"
                    aria-label="loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {!isLoading && filteredTables.length === 0 && (
                <div className="mb-2 text-sm text-gray-500">No tables found</div>
              )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
