import React from 'react';

interface LoadingAndErrorSectionProps {
  isLoading: boolean;
  isFailed: boolean;
  errorMessage: string;
}

export default function LoadingAndErrorSection(props: LoadingAndErrorSectionProps): JSX.Element {
  if (!props.isLoading && !props.isFailed) {
    return <></>;
  }

  return (
    <div className="mt-4 pb-6 max-w-md mx-[auto]">
      {props.isLoading && !props.isFailed && (
        <div className="min-h-6 flex items-center justify-center">
          <div
            className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {props.isFailed && (
        <div className="min-h-6 flex items-center justify-center">
          <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
            <a className="w-full text-center font-medium text-red-600 hover:text-red-500 break-words p-1 px-4">
              {props.errorMessage}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
