import React, { useState, useEffect, useRef } from 'react';
import { useGenerateDataProductSpecificationMutation } from 'app/apis';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectCreateNewProject,
  selectSelectedGroup,
  selectSelectedProject,
  selectProjectsCreatedInCurrentSession,
  selectNewProjectName,
  setProjectPath,
  setProjectsCreatedInCurrentSession,
  setNewProjectName,
  setBranch,
} from 'app/sharedSlice';
import {
  selectDataProductName,
  selectDataProductDescription,
  selectDataProductOwner,
  selectDataProductVersion,
  setDataQualityTests,
  setDataProductName,
  setDataProductDescription,
  setDataProductOwner,
  setDataProductVersion,
  selectDataQualityTests,
} from '../reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';
import GroupAndProjectSelector, { sanitizeProjectName } from 'components/GroupAndProjectSelector';
import InformationTooltip from 'components/InformationTooltip';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';

export interface DataProductDefinitionStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function DataProductDefinitionStep(props: DataProductDefinitionStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const dataProductName = useAppSelector(selectDataProductName);
  const dataProductDescription = useAppSelector(selectDataProductDescription);
  const dataProductOwner = useAppSelector(selectDataProductOwner);
  const dataProductVersion = useAppSelector(selectDataProductVersion);
  const dataQualityTests = useAppSelector(selectDataQualityTests);
  const createNewProject = useAppSelector(selectCreateNewProject);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const selectedProject = useAppSelector(selectSelectedProject);
  const projectsCreatedInCurrentSession = useAppSelector(selectProjectsCreatedInCurrentSession);
  const newProjectName = useAppSelector(selectNewProjectName);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('Failed to generate config');

  const [generateDataProductSpecification] = useGenerateDataProductSpecificationMutation();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    dispatch(setNewProjectName(sanitizeProjectName(dataProductName)));
  }, [dataProductName]);

  const onContinue = (): void => {
    if (formRef.current?.reportValidity() === true) {
      setLoading(true);
      setFailed(false);
      let projectName = '';
      if (createNewProject) {
        if (newProjectName === '') return;
        const newProjectPath = `${selectedGroup.path}/${newProjectName}`;
        console.log('New project path:', newProjectPath);
        dispatch(setProjectPath(newProjectPath));
        projectName = newProjectPath;
      } else {
        if (selectedProject.name === '') return;
        console.log('Selected project: ', selectedProject);
        dispatch(setProjectPath(selectedProject.path));
        projectName = selectedProject.path;
      }

      const projectAlredyCreatedInThisSession = projectsCreatedInCurrentSession.includes(projectName);
      const isNewProject = createNewProject && !projectAlredyCreatedInThisSession;

      generateDataProductSpecification({
        projectName,
        isNewProject,
        name: dataProductName,
        description: dataProductDescription,
        dataQualityTests,
        version: dataProductVersion,
        owner: dataProductOwner,
        group: selectedGroup.path,
      })
        .unwrap()
        .then((res: any) => {
          console.log(res);
          dispatch(setBranch(res));
          dispatch(setProjectsCreatedInCurrentSession([...projectsCreatedInCurrentSession, projectName]));
          setSuccess(true);
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
          setErrorMessage(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (success) {
      props.onContinue();
    }
  }, [success]);

  return (
    <StepWrapper
      title="What's the definition of your data product?"
      subtitle="Define the data product properties"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      continueDisabled={
        loading ||
        selectedGroup.name === '' ||
        (createNewProject && newProjectName === '') ||
        (!createNewProject && selectedProject.name === '')
      }
    >
      <div className="h-full">
        <form ref={formRef}>
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-6">
            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={dataProductName}
                    onChange={(e) => dispatch(setDataProductName(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Customer Insight Dashboard"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <textarea
                  id="decription"
                  name="description"
                  value={dataProductDescription}
                  onChange={(e) => dispatch(setDataProductDescription(e.target.value))}
                  rows={5}
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  placeholder="This data product consolidates customer interaction data across multiple channels – including sales, customer service, and online engagement – to provide a comprehensive view of customer behaviours, preferences, and feedback. We will use this dashboard to segment our customer base, identify trends, and tailor our marketing strategies effectively and make data-driven decisions that enhance customer satisfaction and drive business growth."
                  required
                />
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="owner" className="block text-sm font-medium leading-6 text-gray-900">
                Owner
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    id="owner"
                    name="owner"
                    value={dataProductOwner}
                    onChange={(e) => dispatch(setDataProductOwner(e.target.value))}
                    autoComplete="owner"
                    placeholder="Jane Doe"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2 xl:col-span-1">
              <label htmlFor="version" className="block text-sm font-medium leading-6 text-gray-900">
                Version
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    id="version"
                    name="version"
                    value={dataProductVersion}
                    onChange={(e) => dispatch(setDataProductVersion(e.target.value))}
                    autoComplete="version"
                    placeholder="0.1.0"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-2">
              <label htmlFor="tests-required" className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                <div>Service Level Objective (SLO)</div>
                <InformationTooltip tooltip="SLO is a collection of objectives and specific targets a data product aims to achieve. For example, an SLO may include objectives declaring that data is no more than 4 hours old or that 98% of rows pass quality checks." />
              </label>
              <div className="mt-2">
                <textarea
                  id="tests-required"
                  name="tests-required"
                  value={dataQualityTests}
                  onChange={(e) => dispatch(setDataQualityTests(e.target.value))}
                  rows={5}
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  placeholder="To keep the data product reliable, important quality tests are generated automatically once the dataset is created and loaded. These tests depend on the predefined rules used to evaluate the quality of the columns you select in a subsequent step. For example, tests are generated to check the completeness of fields like customer names and transaction dates, ensuring consistency and removing duplicates."
                  required
                />
              </div>
            </div>

            <GroupAndProjectSelector />
          </div>
        </form>
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />
      </div>
    </StepWrapper>
  );
}
