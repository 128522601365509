import React from 'react';

interface DividerWithTextProps {
  dividerText: string;
}

export default function DividerWithText(props: DividerWithTextProps): JSX.Element {
  return (
    <div className="w-full relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-sm text-gray-500">{props.dividerText}</span>
      </div>
    </div>
  );
}
