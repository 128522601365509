import React, { useMemo } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectSelectedDatabase } from 'app/snowflakeSlice';
import { selectSelectedDatabases, selectSelectedSchemas, selectSelectedTables } from '../reducers/builderSlice';
import DatabaseItem from './DatabaseItem';

interface SourceSelectorProps {
  mode: 'selection' | 'preview';
}

export default function SourceSelector(props: SourceSelectorProps): JSX.Element {
  const selectedDatabase = useAppSelector(selectSelectedDatabase);
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);

  const databases = useMemo(() => {
    if (props.mode === 'selection') {
      return [selectedDatabase];
    }
    const databaseSelected = selectedDatabases.some((database) => database.database === selectedDatabase);
    if (databaseSelected) return [selectedDatabase];

    const schemaSelected = selectedSchemas.some((schema) => schema.database === selectedDatabase);
    if (schemaSelected) return [selectedDatabase];

    const tableSelected = selectedTables.some((table) => table.database === selectedDatabase);
    if (tableSelected) return [selectedDatabase];

    return [];
  }, [selectedDatabase, selectedDatabases, selectedSchemas, selectedTables]);

  return (
    <>
      <div className="w-full max-w-[25rem] flex flex-col items-center space-y-1">
        {databases?.map((database) => (
          <DatabaseItem key={database} databaseName={database} openByDefault mode={props.mode} />
        ))}
      </div>
      {props.mode === 'preview' && databases.length === 0 && <div className="text-gray-600">No objects selected</div>}
    </>
  );
}
