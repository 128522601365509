import React, { Fragment, useEffect } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import InformationTooltip from 'components/InformationTooltip';
import { classNames } from 'utils/styleUtils';

interface IOption {
  name: string;
  id: number;
  path: string;
}

interface ComboboxAutocompleteProps {
  label: string;
  options: IOption[];
  selected: IOption;
  setSelected: (value: IOption) => void;
  query: string;
  setQuery: (value: string) => void;
  tooltip?: string;
}

const ComboboxAutocomplete = (props: ComboboxAutocompleteProps): JSX.Element => {
  // If there is only one option, select it automatically
  useEffect(() => {
    if (props.options.length === 1) {
      props.setSelected(props.options[0]);
    }
  }, [props.options]);

  return (
    <div className="w-full">
      <Combobox value={props.selected} onChange={props.setSelected}>
        {({ open }) => (
          <>
            <div className="relative mt-1">
              <Combobox.Label className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                <div>{props.label}</div>
                {props.tooltip !== undefined && <InformationTooltip tooltip={props.tooltip} />}
              </Combobox.Label>
              <div className="mt-1 relative w-full cursor-default rounded-md bg-white text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 sm:text-sm sm:leading-6 flex items-center">
                <Combobox.Button as="div" className="flex">
                  <Combobox.Input
                    key={open ? 'open' : 'closed'}
                    className="w-full py-1.5 pl-1.5 pr-10 text-black bg-transparent border-none text-sm focus:outline-none"
                    onChange={(event) => props.setQuery(event.target.value)}
                    displayValue={() => {
                      if (open) {
                        return '';
                      }
                      return props.selected.name;
                    }}
                    required
                  />
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                // afterLeave={() => props.setQuery('')}
              >
                <Combobox.Options className="absolute z-50 mt-1 max-h-60 min-w-full max-w-[25rem] overflow-auto rounded-md bg-white pt-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {props.options.length === 0 && props.query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
                  ) : (
                    <>
                      {props.options.map((option) => (
                        <Combobox.Option
                          key={option.name}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-gray-900 bg-gray-100' : 'text-gray-900',
                              'relative cursor-default select-none py-1 pl-3 pr-9',
                            )
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className="block text-xs text-gray-500 truncate">{option.path}</span>
                              <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate')}>
                                {option.name}
                              </span>
                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-gray-600 bg-gray-100' : 'text-gray-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-2',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </>
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </>
        )}
      </Combobox>
    </div>
  );
};

export default ComboboxAutocomplete;
