import { ApolloClient, InMemoryCache, type DefaultOptions } from '@apollo/client';
import { getConfig } from './config/config-helper';

const { dataopsGraphQlApiEndpoint } = getConfig();

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  uri: dataopsGraphQlApiEndpoint,
  cache: new InMemoryCache(),
  defaultOptions,
});

export default client;
