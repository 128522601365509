import { createApi, retry } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import queryString from 'query-string';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import type { ISchemaLevelObject } from 'utils/types';

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        paramsSerializer: {
          serialize: (params) => {
            return queryString.stringify(params);
          },
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data ?? err.message,
        },
      };
    }
  };

export const builderApi = createApi({
  reducerPath: 'builderApi',
  baseQuery: retry(
    axiosBaseQuery({
      baseUrl: '/api/v1',
    }),
    {
      retryCondition: (e: any, __, { attempt }) => {
        if (e.originalStatus !== undefined && e.originalStatus >= 500 && e.originalStatus < 600 && attempt <= 3) {
          return true;
        }
        return false;
      },
    },
  ),
  keepUnusedDataFor: 600,
  tagTypes: ['Builder', 'SnowflakeAccount'],
  endpoints: (builder) => ({
    generateDataProductSpecification: builder.mutation({
      query: ({ projectName, isNewProject, name, description, dataQualityTests, version, owner, group }) => ({
        url: '/builder/generate_data_product_specification',
        method: 'POST',
        params: {
          project_name: projectName,
          is_new_project: isNewProject,
        },
        data: { name, description, data_quality_tests: dataQualityTests, version, owner, group },
      }),
      invalidatesTags: [],
    }),
    generateSoleFromObjects: builder.mutation({
      query: ({ account, databases, schemas, tables }) => ({
        url: '/builder/generate_sole_from_objects',
        method: 'POST',
        params: { account },
        data: { databases, schemas, tables },
      }),
      invalidatesTags: [],
    }),
    generateSoleFromDDL: builder.mutation({
      query: ({ ddl }) => ({
        url: '/builder/generate_sole_from_ddl',
        method: 'POST',
        data: { ddl },
      }),
      invalidatesTags: [],
    }),
    sole: builder.mutation({
      query: ({
        dbtSole,
        manageSole,
        toIncludeModels,
        toWriteConfigs,
        toWriteJobs,
        dataProductName,
        projectName,
        account,
        branch,
        augment,
      }) => ({
        url: '/builder/sole',
        method: 'POST',
        params: {
          to_include_models: toIncludeModels,
          to_write_configs: toWriteConfigs,
          to_write_jobs: toWriteJobs,
          data_product_name: dataProductName,
          project_name: projectName,
          account,
          branch,
          augment,
        },
        data: { manage_sole: manageSole, dbt_sole: dbtSole },
      }),
      invalidatesTags: [],
    }),
    pipeline: builder.mutation({
      query: ({ dataProductName, projectName, branch, mergeMain }) => ({
        url: '/builder/pipeline',
        method: 'POST',
        params: {
          data_product_name: dataProductName,
          project_name: projectName,
          branch,
          merge_main: mergeMain,
        },
      }),
      invalidatesTags: [],
    }),
    addSnowflakeAccount: builder.mutation({
      query: ({ account, username, password, warehouse, role }) => ({
        url: '/snowflake_accounts/add_snowflake_account',
        method: 'POST',
        data: {
          account,
          username,
          password,
          warehouse,
          role,
          persist: false,
        },
      }),
      invalidatesTags: ['SnowflakeAccount'],
    }),
    getDatabases: builder.query<string[], { account: string }>({
      query: (params) => ({
        url: '/builder/databases',
        params: { ...params },
      }),
      providesTags: ['SnowflakeAccount'],
    }),
    getSchemas: builder.query<string[], { account: string; database: string }>({
      query: (params) => ({
        url: '/builder/schemas',
        params: { ...params },
      }),
      providesTags: ['SnowflakeAccount'],
    }),
    getSchemaLevelObjects: builder.query<
      string[],
      { account: string; database: string; schema: string; object_type: ISchemaLevelObject }
    >({
      query: (params) => ({
        url: '/builder/schema_level_objects',
        params: { ...params },
      }),
      providesTags: ['SnowflakeAccount'],
    }),
    createDbtMigrationProject: builder.mutation({
      query: ({ projectName, isNewProject }) => ({
        url: '/builder/create_dbt_migration_project',
        method: 'POST',
        params: {
          project_name: projectName,
          is_new_project: isNewProject,
        },
      }),
      invalidatesTags: [],
    }),
    generateDbtJobs: builder.mutation({
      query: ({
        projectName,
        masterDatabase,
        account,
        dbtVersion,
        branch,
        jobList,
        seedSelector,
        sourceSelector,
        modelSelector,
      }) => ({
        url: '/builder/generate_dbt_jobs',
        method: 'POST',
        params: {
          project_name: projectName,
          master_database: masterDatabase,
          account,
          dbt_version: dbtVersion,
          branch,
        },
        data: {
          jobs_list: jobList,
          seed_selector: seedSelector,
          source_selector: sourceSelector,
          models_selector: modelSelector,
        },
      }),
      invalidatesTags: [],
    }),
  }),
});

export const {
  useGenerateDataProductSpecificationMutation,
  useGenerateSoleFromObjectsMutation,
  useGenerateSoleFromDDLMutation,
  useSoleMutation,
  usePipelineMutation,
  useAddSnowflakeAccountMutation,
  useGetDatabasesQuery,
  useGetSchemasQuery,
  useGetSchemaLevelObjectsQuery,
  useCreateDbtMigrationProjectMutation,
  useGenerateDbtJobsMutation,
} = builderApi;
