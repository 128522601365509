import React from 'react';
import { useAppSelector } from 'app/hooks';
import { selectProjectPath, selectSelectedGroup } from 'app/sharedSlice';
import { selectSnowflakeAccount, selectSelectedDatabase } from 'app/snowflakeSlice';
import StepWrapper from 'components/StepWrapper';
import OverviewBlock from 'components/OverviewBlock';

export interface DataProductSummaryStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function DataProductSummaryStep(props: DataProductSummaryStepProps): JSX.Element {
  const projectPath = useAppSelector(selectProjectPath);
  const selectedGroup = useAppSelector(selectSelectedGroup);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabase = useAppSelector(selectSelectedDatabase);

  const stats = [
    { name: 'Folders', value: 2 },
    { name: 'Files', value: 50 },
    { name: 'Models', value: 10 },
    { name: 'Sources', value: 20 },
  ];

  return (
    <StepWrapper
      title="Summary"
      subtitle="Review your new project"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="px-6 w-full wide:w-3/4 mx-[auto]">
        <div className="w-full">
          <OverviewBlock dividerText="New project details" backgroundColor="bg-gray-50">
            <dl className="grid grid-cols-2">
              <div className="border-gray-100 px-4 py-3">
                <dt className="text-sm font-semibold text-gray-900">Group</dt>
                <dd className="text-sm text-gray-700">{selectedGroup.name}</dd>
              </div>
              <div className="border-gray-100 px-4 py-3">
                <dt className="text-sm font-semibold text-gray-900">Project</dt>
                <dd className="text-sm text-gray-700 truncate">{projectPath}</dd>
              </div>
            </dl>
          </OverviewBlock>
        </div>
        <div className="w-full">
          <OverviewBlock dividerText="DBT project">
            <div className="text-sm text-gray-600 px-4">
              <dl className="border border-gray-300 rounded-md overflow-hidden mx-auto grid grid-cols-4 gap-px bg-gray-900/5">
                {stats.map((stat) => (
                  <div
                    key={stat.name}
                    className="flex flex-wrap items-baseline justify-between gap-x-2 gap-y-1 bg-white px-6 py-4"
                  >
                    <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </OverviewBlock>
        </div>

        <OverviewBlock dividerText="Snowflake account" backgroundColor="bg-gray-50">
          <div className="px-6 text-sm text-gray-600">
            The metadata was imported from the following Snowflake account:{' '}
            <span className="font-semibold text-gray-800">{snowflakeAccount}</span>
          </div>
          <div className="mt-2 px-6 text-sm text-gray-600">The selected target database is: {selectedDatabase}</div>
        </OverviewBlock>
      </div>
    </StepWrapper>
  );
}
