import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectCurrentStep, setCurrentStep, setNumberOfSteps } from 'app/sharedSlice';
import Steps from 'components/Steps';
import type { IStep } from 'utils/types';

interface FlowViewProps {
  getSteps: () => IStep[];
  mainPanelComponent: React.ReactNode;
  completedComponent: React.ReactNode;
}

export default function FlowView(props: FlowViewProps): JSX.Element {
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);

  const numberOfSteps = props.getSteps().length;

  useEffect(() => {
    dispatch(setNumberOfSteps(numberOfSteps));
  }, [numberOfSteps]);

  if (currentStep === props.getSteps().length) {
    return <>{props.completedComponent}</>;
  }

  return (
    <div className="w-full flex">
      <div className="w-1/3 wide:w-1/4 absolute left-0 bottom-0 top-[4rem] p-4 pr-2 overflow-y-scroll">
        <div className="bg-white p-8 rounded-md drop-shadow-md">
          <Steps
            getSteps={props.getSteps}
            currentStep={currentStep}
            setCurrentStep={(step: number) => dispatch(setCurrentStep(step))}
          />
        </div>
      </div>
      <div className="w-2/3 wide:w-3/4 absolute right-0 bottom-0 top-[4rem] p-4 pl-2">
        <div className="h-full bg-white p-8 selection rounded-md drop-shadow-md">{props.mainPanelComponent}</div>
      </div>
    </div>
  );
}
