import React from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 300,
};

export default function NotAvailableFlowButton(): JSX.Element {
  const { tooltipOpen, tooltipLeft, tooltipTop, hideTooltip, showTooltip } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  return (
    <>
      <button
        className="absolute bottom-6 right-8 min-w-[8rem] rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-gray-400 cursor-default"
        ref={containerRef}
        onMouseLeave={() => {
          hideTooltip();
        }}
        onMouseMove={(event) => {
          const eventSvgCoords = localPoint(event);
          showTooltip({
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: eventSvgCoords?.x,
          });
        }}
      >
        Select
      </button>
      {tooltipOpen && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">For further information please contact productmanagement@dataops.live</div>
        </TooltipInPortal>
      )}
    </>
  );
}
