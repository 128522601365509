import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

interface InfoSectionProps {
  text: string;
}

export default function InfoSection(props: InfoSectionProps): JSX.Element {
  return (
    <div className="rounded-md bg-blue-50 p-4 flex">
      <div className="flex-shrink-0">
        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-sm text-blue-700">{props.text}</p>
      </div>
    </div>
  );
}
