import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectCurrentStep, setCurrentStep } from 'app/sharedSlice';
import TargetProjectStep from 'features/dbt-migration/steps/TargetProjectStep';
import SourceProjectStep from 'features/dbt-migration/steps/SourceProjectStep';
import TargetDatabaseStep from 'features/dbt-migration/steps/TargetDatabaseStep';
import ModelSelectionStep from 'features/dbt-migration/steps/ModelSelectionStep';
import DataProductSummaryStep from 'features/dbt-migration/steps/DataProductSummaryStep';

export default function DbtMigrationView(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);

  return (
    <>
      {currentStep === 0 && (
        <TargetProjectStep onBack={() => navigate('/')} onContinue={() => dispatch(setCurrentStep(1))} />
      )}
      {currentStep === 1 && (
        <SourceProjectStep onBack={() => dispatch(setCurrentStep(0))} onContinue={() => dispatch(setCurrentStep(2))} />
      )}
      {currentStep === 2 && (
        <TargetDatabaseStep onBack={() => dispatch(setCurrentStep(1))} onContinue={() => dispatch(setCurrentStep(3))} />
      )}
      {currentStep === 3 && (
        <ModelSelectionStep onBack={() => dispatch(setCurrentStep(2))} onContinue={() => dispatch(setCurrentStep(4))} />
      )}
      {currentStep === 4 && (
        <DataProductSummaryStep
          onBack={() => dispatch(setCurrentStep(3))}
          onContinue={() => dispatch(setCurrentStep(5))}
        />
      )}
    </>
  );
}
