import React, { useState } from 'react';
import { useAddSnowflakeAccountMutation } from 'app/apis';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectSnowflakeAccount,
  selectSnowflakeUsername,
  selectSnowflakeWarehouse,
  selectSnowflakeRole,
  selectSnowflakeAccountConnected,
  setSnowflakeAccount,
  setSnowflakeUsername,
  setSnowflakeWarehouse,
  setSnowflakeRole,
  setSnowflakeAccountConnected,
  setSelectedDatabase,
} from 'app/snowflakeSlice';
import { clearSelectedObjects } from '../features/data-product-builder/reducers/builderSlice';
import { Switch } from '@headlessui/react';
import InformationTooltip from './InformationTooltip';
import { classNames } from 'utils/styleUtils';

function parseAccountFromURL(url: string): string {
  const start = 'https://';
  const end = '.snowflakecomputing.com';
  const endWithSlash = '.snowflakecomputing.com/';
  const splitParts = url.split('.');
  if (url.startsWith(start) && url.endsWith(end)) {
    const account = url.substring(start.length, url.length - end.length);
    return account;
  } else if (url.startsWith(start) && url.endsWith(endWithSlash)) {
    const account = url.substring(start.length, url.length - endWithSlash.length);
    return account;
  } else if (splitParts.length === 2) {
    const account = splitParts.join('-');
    return account;
  }
  return url;
}

export default function SnowflakeAccountForm(props: {}): JSX.Element {
  const dispatch = useAppDispatch();
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const snowflakeUsername = useAppSelector(selectSnowflakeUsername);
  const snowflakeWarehouse = useAppSelector(selectSnowflakeWarehouse);
  const snowflakeRole = useAppSelector(selectSnowflakeRole);
  const snowflakeAccountConnected = useAppSelector(selectSnowflakeAccountConnected);
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Couldn't connect to Snowflake");
  const [trimAccount, setTrimAccount] = useState(true);

  const [addSnowflakeAccount] = useAddSnowflakeAccountMutation();

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    dispatch(setSnowflakeAccountConnected(false));
    setFailed(false);
    setLoading(true);
    addSnowflakeAccount({
      account: snowflakeAccount,
      username: snowflakeUsername,
      password,
      warehouse: snowflakeWarehouse,
      role: snowflakeRole,
    })
      .unwrap()
      .then((res: any) => {
        console.log(res);
        dispatch(setSnowflakeAccountConnected(true));
        setFailed(false);
        dispatch(setSnowflakeAccount(snowflakeAccount));
        dispatch(setSnowflakeUsername(snowflakeUsername));
        dispatch(setSnowflakeWarehouse(snowflakeWarehouse));
        dispatch(setSnowflakeRole(snowflakeRole));
        dispatch(setSelectedDatabase(''));
        dispatch(clearSelectedObjects());
      })
      .catch((err) => {
        console.log(err);
        dispatch(setSnowflakeAccountConnected(false));
        if (err.data !== undefined) setErrorMessage(err.data);
        else setErrorMessage("Couldn't connect to Snowflake");
        setFailed(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form className="space-y-4 flex flex-col items-center" onSubmit={handleSubmit}>
      <div className="rounded-md shadow-sm w-fit">
        <Switch.Group as="div" className="flex items-center justify-center ml-[auto] mb-1">
          <Switch
            checked={trimAccount}
            onChange={() => setTrimAccount(!trimAccount)}
            className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
          >
            <span className="sr-only">Use setting</span>
            <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
            <span
              aria-hidden="true"
              className={classNames(
                trimAccount ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
              )}
            />
            <span
              aria-hidden="true"
              className={classNames(
                trimAccount ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-1 cursor-default flex items-center">
            <span className="text-sm font-medium text-gray-700">Trim account</span>
            <InformationTooltip tooltip="If turned on, the Snowflake account URL you paste in the Account field is automatically cleaned and formatted. Turn off the toggle if you are unhappy with the result." />
          </Switch.Label>
        </Switch.Group>
        <div className="flex rounded-md shadow-sm">
          <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
            Account
          </span>
          <input
            value={snowflakeAccount}
            name="account"
            id="account-field"
            onChange={(e) => dispatch(setSnowflakeAccount(e.target.value))}
            onPaste={(e) => {
              e.preventDefault();
              const pastedText = e.clipboardData.getData('text/plain');
              const parsedAccount = trimAccount ? parseAccountFromURL(pastedText) : pastedText;
              dispatch(setSnowflakeAccount(parsedAccount));
            }}
            placeholder="zdxupxd-training"
            required
            className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          />
        </div>
        <div className="flex rounded-md shadow-sm">
          <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
            Username
          </span>
          <input
            value={snowflakeUsername}
            name="username"
            id="username-field"
            onChange={(e) => dispatch(setSnowflakeUsername(e.target.value))}
            placeholder="dataops_obs"
            required
            className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          />
        </div>
        <div className="flex rounded-md shadow-sm">
          <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
            Password
          </span>
          <input
            type="password"
            name="password"
            id="password-field"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Your password"
            required
            className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          />
        </div>
        <div className="flex rounded-md shadow-sm">
          <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
            Warehouse
          </span>
          <input
            value={snowflakeWarehouse}
            name="warehouse"
            id="warehouse-field"
            onChange={(e) => dispatch(setSnowflakeWarehouse(e.target.value))}
            placeholder="DATAOPS_OBS_WH"
            required
            className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          />
        </div>
        <div className="flex rounded-md shadow-sm">
          <span className="w-[8rem] inline-flex items-center border border-r-0 border-gray-300 bg-gray-50 px-2 text-gray-500 sm:text-sm">
            Role
          </span>
          <input
            value={snowflakeRole}
            name="role"
            id="role-field"
            onChange={(e) => dispatch(setSnowflakeRole(e.target.value))}
            placeholder="DATAOPS_OBS_VIEWER"
            className="relative w-[14rem] block appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
          />
        </div>
      </div>
      {loading && !failed && (
        <div className="min-h-6 flex items-center justify-center">
          <div
            className="animate-spin inline-block w-6 h-6 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <button
        type="submit"
        className="w-[22rem] bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300"
      >
        Connect
      </button>
      <div className="w-[22rem]">
        {failed && (
          <div className="min-h-6 w-full flex items-center justify-center">
            <div className="text-sm w-full rounded bg-red-50 border-2 border-red-500 flex items-center justify-center">
              <a className="w-full text-center font-medium text-red-600 hover:text-red-500 break-words p-1">
                {errorMessage}
              </a>
            </div>
          </div>
        )}
        {snowflakeAccountConnected && (
          <div className="min-h-6 w-full flex items-center justify-center">
            <div className="text-sm w-full rounded bg-green-50 border-2 border-green-500 flex items-center justify-center">
              <a className="font-medium text-green-600 hover:text-green-500">Account added</a>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}
