import React from 'react';
import DividerWithText from './DividerWithText';
import { classNames } from 'utils/styleUtils';

interface OverviewBlockProps {
  dividerText: string;
  children: React.ReactNode;
  backgroundColor?: string;
  showEditButton?: boolean;
  onEditClick?: () => void;
  showRightBorder?: boolean;
}

export default function OverviewBlock(props: OverviewBlockProps): JSX.Element {
  return (
    <div className="w-full">
      <DividerWithText dividerText={props.dividerText} />
      <div
        className={classNames(
          props.backgroundColor !== undefined ? `${props.backgroundColor}` : '',
          props.showRightBorder === true ? 'border-r border-gray-300' : '',
          'w-full pt-6 pb-12 flex flex-col items-end',
        )}
      >
        <div className="w-full">{props.children}</div>
        {props.showEditButton === true && (
          <button
            type="button"
            onClick={props.onEditClick !== undefined ? props.onEditClick : undefined}
            className="mx-10 text-sm font-semibold leading-6 text-dataops-secondary-blue hover:text-hover-secondary-blue"
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
}
